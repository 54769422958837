.channel-detail-page {
    width: 100%;
    margin: 0 auto;
    background-color: white;
    height: 100vh;
}

.channel-detail-page-top {
    position: relative;
    height: 350px; 
    overflow: hidden;
}

.blur-section {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url('https://qsf.cf2.quoracdn.net/-4-ans_frontend_assets.images.tribes.defaults.space_banner_yellow.png-26-0cad087b263ce130.png'); /* Replace 'coverPhoto.jpg' with your actual cover photo */
    background-size: cover;
}

.blur-section::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.8; 
}

.channel-cover-img{
    position: absolute;
    top: 0;
    left: 15%;
    width: 70%;
    height: 100%;
    overflow: hidden;
 
    
}
.channel-cover-img img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%; 
    object-fit: cover;
}
.channel-profile-img {
    position: absolute;
    top: 35%;
    left: 20%;
    transform: translateX(-50%);
    width: 100px; 
    height: 100px;
    border-radius: 30%;
    overflow: hidden;
}

.channel-profile-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    
}

.channel-detail-content {
    position: absolute;
    top: 67%;
    left: 16%;
    color: white;
}
.channel-detail-page-lower{
    background-color: #F3F4F6;
     padding-left: 15%;
     display: flex;
     flex-direction: column;
     gap: 10px;
     padding-top: 15px;
     padding-bottom: 10px;
}
.channel-detail-page-lower-top{
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}